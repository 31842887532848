<template>
    <div class="mx-3">
        <h2 class="px-5 py-5">{{ $t('Total Loan Report') }}</h2>
      <v-row class="px-10 py-1">
        <v-col md="3">
          <v-autocomplete
          :label="$t('hr.time.emp-name')"
              :items="EmpItems"
              :loading="EmpLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="filters.employee"
              @keypress="fetchAutoCompleteEmpName($event)" 
            >
            </v-autocomplete>
        </v-col>
        
        <v-col md="3">
          <v-autocomplete
          :label="$t('hr.employee.contract')"
              :items="contractItems"
              :loading="contractLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="filters.contract"
              @keypress="fetchAutoCompleteContract($event)" 
            >
            </v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from">
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to">
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-autocomplete
              :label="$t('hr.employee.shift')"
              :items="ShiftItems"
              :loading="ShiftLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="filters.shift_id"
              @keypress="fetchAutoCompleteShift($event)"
            >
            </v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-autocomplete
              :label="$t('hr.loan.cash')"
              :items="accountItems"
              :loading="accountLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="filters.cash_account_id"
              @keypress="fetchAutoCompleteAccount($event)"
            >
            </v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-autocomplete
              :label="$t('hr.loan.type')"
              :items="loanItems"
              :loading="loanLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="filters.loan_type"
              @keypress="fetchAutoCompleteLoan($event)"
            >
            </v-autocomplete>
        </v-col>
        <v-col md="3">
        <v-radio-group :dark="$store.state.isDarkMode"  row v-model="filters.activate" >
          <v-radio :label="$t('Activate')" value="true" ></v-radio>
          <v-radio :label="$t('All')" value="false"></v-radio>
        </v-radio-group>
        </v-col>
        <v-col md="3" >
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" >
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="3" >
          <v-btn color="blue" dark block  @click="exportToExcel(filters)"
            >{{ $t('hr.time.export') }}
            </v-btn>
        </v-col>
      </v-row>
  
      <v-data-table :dark="$store.state.isDarkMode"
        :headers="headers"
        :loading="loading"
        :items="tableItems"
        class="elevation-1 px-10"
        hide-default-footer
        :items-per-page="10"
      >
    <template  v-slot:item.actions="{ item }">
        
        <v-btn @click="detailsHandler(item)" color="blue" icon>
          <v-icon class="ml-1">mdi-eye</v-icon></v-btn
        >
      </template>
      </v-data-table>
      <v-pagination
        v-if="!loading && tableItems.length"
        @input="getAll"
        v-model="page"
        :length="Math.ceil(total / 10)"
      ></v-pagination>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import i18n from '@/i18n';
  import debounce from "../../../../../helpers/debounce";

import { saveAs } from 'file-saver';

  
  export default {
    components: {    },
    computed:{
    
    },
    data: () => ({
      
      contractItems: [],
      contractLoading: false,
      ShiftItems: [],
      ShiftLoading: false,
      EmpItems: [],
      EmpLoading: false,
      tableItems: [],
      accountItems:[],
      loanItems: [],
      accountLoading:false,
      loading: false,
      page: 1,
      total: 1,
      headers: [  
        { text: i18n.t("serial number"), value: "serial" },
        { text: i18n.t('hr.time.emp-name'), value: "employee_name" },
        { text: i18n.t('hr.loan.Name'), value: "loan_name" },
        { text: i18n.t('date'), value: "date" },
        { text: i18n.t('hr.loan.Value'), value: "value" },
        { text: i18n.t('hr.loan.Payment'), value: "payment_value" },
        { text: i18n.t('hr.loan.Rest'), value: "left_amount" },
        { text: i18n.t('hr.loan.cash'), value: "cash_account" },
        { text: "", value: "actions" },
      ],
      itemsInvoece: [],
      filters: {
        contract: null,
        employee: null,
        shift_id: null,
        from: null,
        to: null,
        cash_account_id: null,
        loan_type: null,
        activate: true,
      },
    }),
    methods: {
     async exportToExcel(queryParams){
         
          try {
     
      const excel = await axios.get(`hr/report/loans/export`, {
        params: {
          ...queryParams,
          
        },
        responseType: 'blob'
      });
      saveAs(excel.data)
      

    } catch (err) {
      console.log('err', err);
    } finally {
      
    }
      
      },
     async fetchTerm(){
        try {
      this.termsLoading = true;
      const response = await axios.get("terms", {
        params: {
          size: 100000,
        },
      });
      this.terms = response.data.terms;
    } catch (err) {
    } finally {
      this.termsLoading = false;
    }
      },
      clearFilter() {
        this.filters.employee = undefined;
        this.filters.shift_id = undefined;
        this.filters.contract = undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
        this.filters.cash_account_id = undefined;
        this.filters.loan_type = undefined;
        this.page = 1;
        this.getAll();
      },
      detailsHandler(item) {
        this.$router.push(
          `detailsLoan/${item.loan_id}`
        );
    },
   
      async getAll(page) {
        try {
          
          if (!page) this.page = 1;
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          
          const res = await axios.get("hr/report/loans", {
            params: {
              size: 10,
              page: this.page,
              ...filterTemp,
            },
          });
          const arr= Array.from(res.data.data)
          if(Array.isArray(arr))
          {
            console.log(arr);
            this.tableItems = arr.map((el) => {
              
              return {
                ...el,
                
              };
            });
          }
            console.log(res.data.data);
          
          this.total = res.data.total;
        } finally {
          this.loading = false;
        }
      },
    
      async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
                cash: 1,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    
    async fetchAutoCompleteLoan(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.loanLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "hr/loan/type/auto-complete",
            {
              params: {
                name: searchValue,
              },
            }
          );
          this.loanItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.loanLoading = false;
        }
      }, 500)();
    },
      async fetchAutoCompleteShift(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.ShiftLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/hr/shift/auto-complete",
            {
              params: {
                name: searchValue,
              },
            }
          );
          this.ShiftItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.ShiftLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteContract(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.contractLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/hr/contract/auto-complete",
            {
              params: {
                name: searchValue,
              },
            }
          );
          this.contractItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.contractLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteEmpName(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.EmpLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/hr/employee/auto-complete",
            {
              params: {
                name: searchValue,
              },
            }
          );
          this.EmpItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.EmpLoading = false;
        }
      }, 500)();
    },
    
    },
    created() {
      this.getAll();
    },
  };
  </script>
  
  <style></style>
  